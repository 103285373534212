import AppContext from "./appContext";
import React, { useState } from "react";
const AppState = (props) => {
  const [state, setState] = useState("Inbox");
  const [updateState, setUpdateState] = useState("Schedule");

  const [stateSideDrawer, setstateSideDrawer] = useState("Inbox");
  const [alert, setAlert] = useState(null);

  const [bnText, setBnText] = useState("");
  const [wmText, setWmText] = useState([]);
  const [sdText, setSdText] = useState("");
  const [scriptText, setScriptText] = useState("");
  const [kbText, setKbText] = useState([]);
  const [tempKbText, setTempKbText] = useState([]);
  const [tempWmText, setTempWmText] = useState([]);

  const [user_session, setUserSession] = useState("");
  const [response_data, setResponseData] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [lastSelectedBot, setLastSelectedBot] = useState(null);
  const [disable_retrain_button, setDisableRetrainButton] = useState(1);
  const [botsList, setBotsList] = useState();
  const [typingTimer, setTypingTimer] =useState(null)
  
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  };

  return (
    <AppContext.Provider
      value={{
        state,
        setState,

        alert,
        setAlert,
        showAlert,

        stateSideDrawer,
        setstateSideDrawer,

        updateState,
        setUpdateState,
        
        bnText,setBnText,
        wmText,setWmText,
        sdText,setSdText,
        kbText,setKbText,
        tempKbText,setTempKbText,
        tempWmText,setTempWmText,
        typingTimer,setTypingTimer,
        selectedBot, setSelectedBot,
        lastSelectedBot, setLastSelectedBot,
        user_session,setUserSession,
        response_data,setResponseData,
        botsList, setBotsList,
        scriptText, setScriptText,
        disable_retrain_button, setDisableRetrainButton
       
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
