import React, { Fragment, useState,  useContext } from "react";
import appContext from "../../context/appContext";
import brand from "../../assets/images/HS-logo.png";
import DashboardIcon from "../../assets/icons/Dashboard.svg";
import ManageTagsIcon from "../../assets/icons/ManageTags.svg";
import SubscriberIcon from "../../assets/icons/Subscriber.svg";
import ReportingIcon from "../../assets/icons/Reporting.svg";
import SettingIcon from "../../assets/icons/Setting.svg";
import activeDashboardIcon from "../../assets/icons/activeDashboard.svg";
import activeManageTagsIcon from "../../assets/icons/activeManageTags.svg";
import activeSubscriberIcon from "../../assets/icons/activeSubscriber.svg";
import activeReportingIcon from "../../assets/icons/activeReporting.svg";
import activeSettingIcon from "../../assets/icons/activeSetting.svg";
import chevronOpen from "../../assets/icons/chevronOpen.svg";
import chevronClose from "../../assets/icons/chevronClose.svg";
import shareIcon from "../../assets/icons/ic_share.svg";
import { NavLink } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import myApi from "../../myApi";

function SideDrawer() {
  const state = useContext(appContext);
  const handleDashboard = () => {
    state.setstateSideDrawer("Inbox");
  };
  const handleManageTags = () => {
    state.setstateSideDrawer("Knowledge Source");
  };
  const handleReporting = () => {
    state.setstateSideDrawer("Reporting");
  };
  const handleSetting = () => {
    state.setstateSideDrawer("Settings");
  };

  const handleIntegration = () => {
    state.setstateSideDrawer("Integration");
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState('');

  // handle input change event
  const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {
    console.log("selected bot" + value.bot_id)
    if(value.bot_id === -1)
    {
      createNewBot()
      return;
    }
    
    state.setSelectedBot(value);

  }
  const createNewBot = () => {
    return  myApi.get('/create_new_bot?page=1').then(result => {
      let res =  result.data;
      let temp = JSON.parse(JSON.stringify(res[0]));
      temp.bot_name="Create New Bot";
      temp.bot_id=-1;
      res.push(temp)
      //fetchUsers()
      state.setSelectedBot(res[0]);
      console.log(res);
      window.location="/knowledge_base";
      //window.location.reload(false);
      return res;
    });
  }

  const fetchUsers = () => {
    return  myApi.get('/get_list_of_bots?page=1').then(result => {
      

      let res =  result.data;
      

      let temp = {bot_name: 'Create New Bot', bot_id: -1} //JSON.parse(JSON.stringify(res[0]));
      // temp.bot_name="";
      // temp.bot_id=-1;
      res.push(temp)
      if(state.selectedBot===null)
      {
        state.setSelectedBot(res[0]);
      }
      else{
        state.setSelectedBot(state.selectedBot);
      }
      console.log(res);
      return res;
    }).catch((err) => {
      console.log(err);
      localStorage.removeItem("access_token");
      window.open("/","_self");
    });;
  }

  return (
    <Fragment>

      <div
        className="h-screen w-[296px] bg-white fixed top-0 left-0"
        id="drawerNav"
      >

        <div className="mt-[22px] pl-[0px] ml-[30px] mb-[20px]">
          <img className="highersummit-logo" src={brand} alt="" />
        </div>

             <div className="dropdown  bg-white rounded-[7px] border-[1px] border-[#8D97A66E]">
             <AsyncSelect
              cacheOptions
              defaultOptions
              value={state.selectedBot}
              getOptionLabel={e => e.bot_name}
              getOptionValue={e => e.bot_id}
              loadOptions={fetchUsers}
              //onInputChange={handleInputChange}
              onChange={handleChange}
            />
          </div>
        
        {state.stateSideDrawer === "Inbox" ? (
          <div
            onClick={handleDashboard}
            className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[30px] py-[15px] border-l-[5px] border-l-[#FFC727] "
            id="activeSideDrawer"
          >
            <img className="" src={activeDashboardIcon} alt="" />
            <NavLink className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] "  to="/">
              Inbox
            </NavLink>
          </div>
        ) : (
          <div
            onClick={handleDashboard}
            className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[30px] py-[15px] border-l-[5px] border-l-[#ffffff] "
            >
            <img className="" src={DashboardIcon} alt="" />
            <NavLink className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] "  to="/">
              Inbox
            </NavLink>
            


          </div>
        )}
        {state.stateSideDrawer === "Knowledge Source" ? (
          <div id="activeSideDrawer">
            <button
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={handleManageTags}
              className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[10px] py-[8px] border-l-[5px] border-l-[#FFC727] "
            >
              <img className="" src={activeManageTagsIcon} alt="" />

              <h1 className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] " >
                Knowledge Source
              </h1>
              <img src={chevronOpen} alt="" />
            </button>
            <div >
            <ul className="cursor-pointer   pl-[100px] py-[15px] space-y-8 text-[#8D97A6] text-[16px] leading-[28.5px] ">
              <li>
                <NavLink className="hover:text-[#4A5478]  font-[400] " to="/knowledge_base">
                  Knowledge Base
                </NavLink>
              </li>
              <li>
                <NavLink className="hover:text-[#4A5478] font-[400] " to="/assign_topic">
                  Assign Topic
                </NavLink>
              </li>
              <li>
                <NavLink className="hover:text-[#4A5478]" to="/b">
                  Assign Answers
                </NavLink>
              </li>
            </ul>
            </div>
          </div>
        ) : (
          <div
            onClick={handleManageTags}
            className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[10px] py-[8px] border-l-[5px] border-l-[#ffffff] "
            >
            <img className="" src={ManageTagsIcon} alt="" />
            <h1 className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] " >
                Knowledge Source
              </h1>
            <img src={chevronClose} alt="" />
          </div>
        )}
      
        {state.stateSideDrawer === "Debug" ? (
          <div
            onClick={() => openInNewTab('https://google.com')}
            className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[10px] py-[8px] border-l-[5px] border-l-[#FFC727] "
            id="activeSideDrawer"
          >
            <img className="" src={activeSubscriberIcon} alt="" />
            <h1 className=" text-[#4A5478] text-[21px] leading-[30.5px] font-[500] ">
            Debug
            </h1>
          </div>
        ) : (
          <div
            onClick={() => openInNewTab(process.env.REACT_APP_REST_API_BASE_URL + '/?debug=true&bot_id='+state.selectedBot.web_widget_access_key)}
            className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[10px] py-[8px]  "
          >
            <img className="" src={SubscriberIcon} alt="" />
            <NavLink className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] " onClick={() => openInNewTab(process.env.REACT_APP_REST_API_BASE_URL + '/?debug=true&bot_id='+state.selectedBot.web_widget_access_key)}>
              Debug
            </NavLink>
          </div>
        )}

          {state.stateSideDrawer === "Integration" ? (
             <div
             onClick={handleIntegration}
             className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[30px] py-[8px] border-l-[5px] border-l-[#FFC727] "
             id="activeSideDrawer"
           >
             <img className="" src={shareIcon} alt="" />
             <NavLink className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] " to="/integration">
             Integration
            </NavLink>
           </div>
        ) : (
          <div
          onClick={handleIntegration}
          className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[30px] py-[8px] border-l-[5px] border-l-[#ffffff] "
          >
            <img className="" src={shareIcon} alt="" />
            <NavLink className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] " to="/integration">
            Integration
            </NavLink>
            


          </div>
        )}


          {state.stateSideDrawer === "Settings" ? (
             <div
             onClick={handleSetting}
             className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[30px] py-[8px] border-l-[5px] border-l-[#FFC727] "
             id="activeSideDrawer"
           >
             <img className="" src={activeSettingIcon} alt="" />
             <NavLink className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] " to="/settings">
              Settings
            </NavLink>
           </div>
        ) : (
          <div
          onClick={handleSetting}
          className="cursor-pointer flex items-center space-x-[17px]  pl-[30px] mt-[30px] py-[8px] border-l-[5px] border-l-[#ffffff] "
          >
            <img className="" src={activeSettingIcon} alt="" />
            <NavLink className=" text-[#4A5478] text-[16px] leading-[30.5px] font-[500] " to="/settings">
              Settings
            </NavLink>
            


          </div>
        )}

        
        <div className="flex justify-center">
          <div className="w-[199px] h-[1px] bg-[#8D97A6] opacity-60 mt-[10px]"></div>
        </div>
      </div>
    </Fragment>
  );
}

export default SideDrawer;
