import axios from 'axios';
import React, { Fragment, useEffect, useContext, useState, useRef } from 'react'
import appContext from "../context/appContext";
import RightSideDrawer from './navbar/RightSideDrawer';
import SideDrawer from './navbar/SideDrawer';
import TextareaAutosize from "react-textarea-autosize";
import { io } from "socket.io-client";

function Inbox() {
    const [response_data, setResponseData] = useState();
    const state = useContext(appContext);
    const selectedUser = useRef(null);
    const [isDisabled, setIsDisabled] = useState(false);
    
    const enableAutoResponse = useRef(false)
    //const [socketSessionID, setSocketSessionID] = useState("");
    const socketInstance = useRef(null)
    
  const checkInputTextEnter = (e) => {
    let userText = e.target.value;
    if (e.key === "Enter") {
      let dt = {target_socket_session_id: selectedUser.current[2], answer: userText, session_id: selectedUser.current[0]}
      
      if(socketInstance.current==null)
      {
        socketInstance.current= io(process.env.REACT_APP_WEB_SOCKET_URL, {
          transports: ["websocket"],
          cors: {
            //origin: "http://localhost:3000/",
          },
        });
      }
      socketInstance.current.emit("data", dt);

      e.target.value = "";
      

    }
  };

  // useEffect(() => {

  //   connectChatSession(enableAutoResponse)

  // },[enableAutoResponse, setEnableAutoResponse]);


  
    const connectChatSession=(buttonStatus)=>
    {
    if (buttonStatus === true) {
      socketInstance.current= io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
        cors: {
          //origin: "http://localhost:3000/",
        },
      });

      socketInstance.current.on("connect", (data) => {
       // console.log(data);
       //setSocketSessionID(socket.id)
       socketInstance.current.emit("update_session_id", {socket_session_id: socketInstance.current.id, user_session_id: state.user_session_id});
        console.log("socket connected");
     //   setConnected(true);
      });

      socketInstance.current.on("disconnect", (data) => {
        //console.log(data);
       // setSocketSessionID("")
        //socket.emit("update_session_id", {session_id: ""});
        console.log("socket disconnected");
      });
  
      socketInstance.current.on("data", (data) => {
        //let prompt = response.data['prompt'];
         //state.setUserSession(response.data['session_name']);
         console.log("manual_response_received");
         console.log(data);
        
         let dt = { "user_session_id":selectedUser.current[0]}
    
         load_user_chat(dt);
  
        var objDiv = document.getElementById("messageBox");
        objDiv.scrollTop = objDiv.scrollHeight;
       // setMessages([...messages, data.data]);
      });
  
    }
    else{
      
      if(socketInstance.current!=null){
        socketInstance.current.disconnect();
      }//setSessionID("")   
    }
  }

    const onTableRowClick=(event)=>{
      event.currentTarget.classList.add('row-selected');
        console.log(event.currentTarget);
        let row = event.currentTarget;
          let userSessionTable =  event.currentTarget.parentElement;
          let rows = userSessionTable.rows;
          for (var i = 0; i < rows.length; i++) {
                    rows[i].classList.remove('row-selected');
                    }
          var cell = row.getElementsByTagName("td")[0];
          var flag = row.getElementsByTagName("td")[2].innerHTML;
          var id = cell.innerHTML;
          row.classList.add('row-selected');
          selectedUser.current[0]=id;
          selectedUser.current[3]=flag;
          let dt = { "user_session_id": id}
          enableAutoResponse.current = Boolean(Number(flag))
          setIsDisabled(Boolean(Number(flag)));
          document.getElementById("chkAutoReply").checked = Boolean(Number(flag));
          load_user_chat(dt);
    }
    
    function load_user_chat(dt)
    {

        console.log(JSON.stringify(dt))
        let messageBox = document.getElementById("messageBox3");

        if(dt===null)
        {
          messageBox.innerHTML="";
          return;
        }

        axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

        axios.request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL+'/get_user_chat',
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify(dt)
          }
          ).then(response =>  {
                console.log(response)
                let user_messages = response.data['user_messages'];
                messageBox.innerHTML=user_messages;
                  
                  messageBox.scrollTop = messageBox.scrollHeight;
                });
    }

    useEffect(() => {

      if(state.selectedBot===null)
      {
        return;
      }
        let tbody = document.getElementById("user_session_tbody");
        axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        console.log(state.selectedBot)

        axios.request({
          method: "GET",
          url: process.env.REACT_APP_REST_API_BASE_URL+'/get_user_sessions?bot_id='+state.selectedBot.bot_id,
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify({
            
          }),
        }).then(response =>  {
                console.log(response.data)

                let user_session = response.data['user_sessions']
               
                setResponseData(response.data['user_sessions']);
                console.log(user_session);
                let dt = null;
                if(user_session.length<=0)
                {
                  return;
                }
                dt = { "user_session_id": user_session[0][0]};
                selectedUser.current = user_session[0];
                var flag = user_session[0][3];
                enableAutoResponse.current = Boolean(Number(flag))
                setIsDisabled(Boolean(Number(flag)));
                document.getElementById("chkAutoReply").checked = Boolean(Number(flag));

                load_user_chat(dt);   
                console.log("enable_auto_response: "+selectedUser.current[3]);
                enableAutoResponse.current = (selectedUser.current[3]);

                });
      },[state.selectedBot]);


      const handleChange = (e) => { 
        connectChatSession(true)
        enableAutoResponse.current = e.target.checked;
        setIsDisabled(e.target.checked);
        selectedUser.current[3]=e.target.checked;
        console.log('The checkbox was toggled: ' + enableAutoResponse.current); 
       socketInstance.current.emit("update_automatic_response_to_manual", {socket_session_id: socketInstance.current.id, user_session_id: selectedUser.current[0], enable_auto_response: enableAutoResponse.current});
      }; 

  return (
    <Fragment>
    
    <SideDrawer/>
    <RightSideDrawer/>
    <div className="pl-[300px]">
    <div className='left-div'>
  <div className="user-session">
  <table id="user-session-table" className="styled-table">
    <thead>
        <tr>
            <th>All Users</th>
            <th></th>
            <th></th>
        </tr>
    </thead>
    <tbody id="user_session_tbody">
        {/* <tr onClick={onTableRowClick}><td>21</td><td>22h</td></tr>
        <tr onClick={onTableRowClick}><td>23</td><td>22h</td></tr> */}

        {response_data?.map((rowData, index) => (
                  <Fragment key={index}>
                    <tr className={index===0 ? "row-selected" : ""} onClick={onTableRowClick}>
                      <td>
                        {rowData[0]}
                      </td>
                      <td>
                        {rowData[1]}
                      </td>
                      <td>
                        {rowData[3]}
                      </td>
                      
                    </tr>
                  </Fragment>
                ))}
    </tbody>
    </table>
  </div>
    
    <div className="chat-window3" id="chat-window3-center" >
    
      <div className="message-box" id="messageBox3">
      </div>
      
      <div id="quickchat-footer2" className='inbox-footer'>
      <input type="checkbox" id="chkAutoReply"  onChange={handleChange} 
       
      name="autoReply" value="Auto Reply"/>
      <label for="vehicle1">Send Auto Reply</label>
          <div id="quickchat-input-container">
            <TextareaAutosize
              id="quickchat-input"
              disabled={isDisabled}
              // disabled={enableAutoResponse.current}
              className='inbox-input-text'
              placeholder="Write a question"
              onKeyPress={e => {
                if(e.key === 'Enter')
                   e.preventDefault()
                }}
              maxLength="250"
              onKeyDown={checkInputTextEnter}
            ></TextareaAutosize>
            <button id="quickchat-send-msg-btn" >
              <svg
                aria-hidden="true"
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
    </div>

    </div>
    </div>
</Fragment>

  )
}

export default Inbox