import React, { Fragment,useEffect,  useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import appContext from "../../context/appContext";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import axios from "axios";
import clsx from "clsx";
import eyeIcon from "../../assets/icons/ic_eye.svg";
import TextareaAutosize from 'react-textarea-autosize';

let responses = [];

function RightSideDrawer() {

  const navigate = useNavigate();
  const state = useContext(appContext);
  const [open, setOpen] = React.useState(false);
  
  const [userMessage, setUserMessage] = useState();
  const [prompt, setPrompt] = useState();
  const timer = useRef([]);
  const [spin, setSpin] = React.useState(false);
	

   const useStyles = makeStyles((theme) => ({
    refresh: {
      marginTop: "-4px",
      cursor: "pointer",
      float: "right",
      margin: "auto",
      "&.spin": {
        animation: "$spin 1s 1",
        pointerEvents:'none'
      }
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)"
      },
      "100%": {
        transform: "rotate(360deg)"
      }
    }
  }));

  const classes = useStyles();

  const refreshCanvas = () => {
		setSpin(true);
		setTimeout(() => {
			setSpin(false);
      get_welcome_messages();
		}, 1000);
	};

  const handleClickOpen = (event, param1, param2) => {
    setUserMessage(param1)
    setPrompt(param2)
    setOpen(true);
  };

  const handleCloseWithYes = () => {

    markQuestionForTopicAssignment();

    setOpen(false);
  };
  const handleCloseWithCancel = () => {
    setOpen(false);
  };

  
  const userResponse=()=> {
    console.log("response");
    let userText = document.getElementById("quickchat-input").value;
    console.log(userText);

    if(isEmptyOrSpaces(userText)){
   
    alert("Please type something!");
    } else {
    
    let arr = [userText,'user','']
      responses.push(arr)
      
      console.log("seting respones list in response_data")
      console.log(responses);
      
      const responses1 = responses.map( x => ({...x}) );
      
      state.setResponseData(responses1);

    let audio3 = new Audio(
      "https://prodigits.co.uk/content/ringtones/tone/2020/alert/preview/4331e9c25345461.mp3"
    );
    audio3.load();
    audio3.play();
    
    let dt = { 'msg': userText ,"forwardMessageFlag": true, "bot_id": state.selectedBot.web_widget_access_key, "session_name": state.user_session}
    document.getElementById("quickchat-input").value = "";
    var objDiv = document.getElementById("messageBox");
    objDiv.scrollTop = objDiv.scrollHeight;

    setTimeout(() => {
     adminResponse(dt);
    }, 1000);
  }
}

function isEmptyOrSpaces(str){
  return str === null || str.match(/^ *$/) !== null;
}

function showTypingIndicator()
{
  let typingIndicator = document.getElementById("typing-indicator");
  typingIndicator.style.display = "block";
}

function hideTypingIndicator()
{
  let typingIndicator = document.getElementById("typing-indicator");
  typingIndicator.style.display = "none";
}

const scrollToBottom = () => {
  this.messagesEnd.scrollIntoView({ behavior: "smooth" });
}

const componentDidMount=()=> {
  this.scrollToBottom();
}

const componentDidUpdate=()=> {
  this.scrollToBottom();
}

function adminResponse(dt) {


  showTypingIndicator();

  console.log(JSON.stringify(dt))

  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

      
  axios.post(process.env.REACT_APP_REST_API_BASE_URL+'/get', { method: 'POST', body: JSON.stringify(dt) })
  
        .then(response => {
          console.log(response)
          //let prompt = response.data['prompt'];
         
           state.setUserSession(response.data['session_name']);
          
           console.log("user_session_is: "+state.user_session);
         
          hideTypingIndicator();
  
    let arr = [response.data['response'],'bot',response.data['prompt']]
    
      responses.push(arr);
      
    state.setResponseData(responses);

    let audio3 = new Audio(
      "https://prodigits.co.uk/content/ringtones/tone/2020/alert/preview/4331e9c25345461.mp3"
    );
    audio3.load();
    audio3.play();
    

    var objDiv = document.getElementById("messageBox");
    objDiv.scrollTop = objDiv.scrollHeight;
  })
  .catch((error) => {
    console.log(error);
  });
}

function get_welcome_messages() {
  responses=[];
  stopWelcomeMessages();
  let dt = { 'bot_id':  state.selectedBot.web_widget_access_key};

  showTypingIndicator();

  console.log(JSON.stringify(dt))

  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

      
  axios.post(process.env.REACT_APP_REST_API_BASE_URL+'/get_welcome_messages', { method: 'POST', body: JSON.stringify(dt) })
  
        .then(response => {
          console.log(response)
          //let prompt = response.data['prompt'];
          var messageBox = document.getElementById("messageBox");
          hideTypingIndicator();

          let user_messages = response.data['user_messages'];

          

          for (var i = 0; i < user_messages.length; i++) { 
                  
            (function(i,timer){
                    var timerRef = setTimeout(function () {
                     // messageBox.innerHTML += "<div class='second-chat2'><div class='circle' id='circle-mar'></div><p class='admin-bot-message'>" + user_messages[i] + "</p></div>";
                      showBotResponse(user_messages[i]);
                    }, 1000 * i);
                    timer.current.push(timerRef);
                  })(i,timer);


              }
            messageBox.scrollTop = messageBox.scrollHeight;
  })
  .catch((error) => {
    console.log(error);
  });
}

function stopWelcomeMessages()
{
    for(var i = 0; i < timer.current.length;i++){
      clearTimeout(timer.current[i]);
    }
    timer.current = [];
}

function showBotResponse(message)
{
        
          let arr = [message,'bot','']
          responses.push(arr)  
          const responses1 = responses.map( x => ({...x}) );
    
          state.setResponseData(responses1);
}

const markQuestionForTopicAssignment = () => {
  
    console.log("calling http request")

    axios.request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL+'/mark_question_for_topic_assignment',
        headers: {
          "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
        },
        data: JSON.stringify({
          question_text: userMessage,
        }),
      })
      .then((res) => {
        console.log(res);
        console.log("received response")
        
        } 
      )
      .catch((err) => {
        console.log("error calling http request")

      });
  
};

  const updateKnowledgeSource = async (e) => {

    //setOpen(true);
    if ("asdf".length > 0) {
      e.preventDefault();
      console.log("calling http request")

      axios.request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL+'/update_knowledge_source',
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify({
            welcome_message: state.tempWmText,
            short_description: state.sdText,
            knowledge_base: state.tempKbText,
            bot_name: state.bnText,
            bot_id:state.selectedBot.bot_id
          }),
        })
        .then((res) => {
          console.log(res);
          console.log("received response")
          console.log(res.data.description)
          
          state.selectedBot.bot_name=state.bnText;
          state.selectedBot.is_bot_trained=1;
          state.setDisableRetrainButton(1);
          
          get_welcome_messages()


          let  savingPreloader = document.getElementById("saving-preloader");
      let savedPreloader = document.getElementById("saved-preloader");
          savedPreloader.getElementsByTagName("span")[0].innerHTML="Bot Retrained Successfully"

          savingPreloader.style.display = "none"
          savedPreloader.style.display = "block"
          
          setTimeout(function(){
            savedPreloader.style.display="none"
            savedPreloader.getElementsByTagName("span")[0].innerHTML="Saved"
          }, 2000);
          
          } 
        )
        .catch((err) => {
          console.log("error calling http request")

        });
    } else {
      alert("Enter valid Email");
    }
  };

  const containerRef = useRef(null);

   useEffect(() => {
    // get_welcome_messages(1);
    console.log("use effect called : ")
    
    if(state.selectedBot===null)
    {
      return;
    }

    if(state.lastSelectedBot === state.selectedBot)
    {
      return;
    }

    
    console.log(state.selectedBot)
    let dt = { 'bot_id': state.selectedBot.bot_id};
  
    //showTypingIndicator();
  
    console.log(JSON.stringify(dt))
  
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  
    axios.request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL+'/get_knowledge_source',
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify(dt) })
          .then(response => {
  
            state.setBnText(response.data['bot_name'])
            state.setWmText(response.data['welcome_messages'])
            state.setSdText(response.data['short_description'])
            state.setKbText(response.data['knowledge_base'])

            state.setTempWmText(response.data['welcome_messages'])
            state.setTempKbText(response.data['knowledge_base'])
            state.setUserSession("");
            get_welcome_messages();
            console.log(response)
            state.setLastSelectedBot(state.selectedBot);
            
    })
    .catch((error) => {
      console.log(error);
    });
    
   },[state.selectedBot]);

   useEffect(() => {
   
    if(containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth"
      })
    }
    
   },[containerRef, state.response_data]);

   const checkInputTextEnter = (e) => {
   // autosize(document.getElementById("quickchat-input"))
    if (e.key === "Enter") {
      userResponse();
    }
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const signOutUser = (event) =>{

    event.preventDefault();
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.request({
          method: "GET",
          url: process.env.REACT_APP_REST_API_BASE_URL+"/logout",
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify({
            
          }),
        })
        .then((res) => {
           
          console.log("res for logout");
          console.log(res);
          localStorage.removeItem("access_token");
          window.open("/","_self");
    
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("access_token");
          window.open("/","_self");
        });
  }

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }
  

  //  useEffect(() => {
  //  setResponseData(response_data)
    
  //  },[response_data]);


  return (

    <Fragment>
      
    <Dialog
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">

        <DialogTitle id="alert-dialog-title">
          {userMessage}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {<div dangerouslySetInnerHTML={{__html: prompt}} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>Cancel</Button>
          <Button onClick={handleCloseWithYes} autoFocus>
            Mark is for wrong topics
          </Button>
        </DialogActions>
      </Dialog>
      
      <div className="h-screen w-[350px] fixed top-0 right-3" >
      <button onClick={() => openInNewTab( ('./preview?bot_id='+state.selectedBot.web_widget_access_key))} className=" text-[#4A5478] text-[21px] leading-[30.5px] font-[500] w-[150px] mt-[23px] preview-button ml-[90px]"  to="/">
      <div className="cursor-pointer flex items-center space-x-[17px]  pl-[20px] py-[15px]   h-[30px]">
            <img className="w-[20px] mr-[10px]" src={eyeIcon} alt="" />
              Preview
          </div>
          </button>

          <div className="dropdown dropdown-style" data-bs-toggle="popover" data-trigger="hover"
           data-container="#dropdownMenuButton" 
           data-placement="bottom" 
           data-content="Profile" data-original-title="" title="" >
          
          <button className="icon-button icon-button-style" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
            <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" ></path></svg>
          </button>

          <div className="dropdown-menu dropdown-menu-style" aria-labelledby="dropdownMenuButton">
            {/* <span className="loggedin-username">umermaju11@gmail.com</span>
            <div className="dropdown-divider"></div>
            <a href="/#" className="dropdown-item" >Settings</a>
            <a href="/#" className="dropdown-item" >Subscription</a>
            <div className="dropdown-divider"></div>
            <a href="/#" className="dropdown-item" >FAQ</a>
            <a href="/#" className="dropdown-item" >Tutorials</a>
            <a href="/#" className="dropdown-item"  id="dropdown-help-center-button">Contact us</a> 
            <div className="dropdown-divider"></div>*/}
            <a className="dropdown-item" onClick={signOutUser} href="/#" >Sign out</a>
          </div>
        </div>

<div className="preview">
    <h1>
    <Autorenew
			className={clsx({
				[classes.refresh]: true,
				spin: spin
			})}
			onClick={refreshCanvas}
			spin={360}
		/>
    </h1>
    <button className="train-my-ai-chatbot-button" id="retrain-ai-button" disabled={(state.selectedBot!==null && state.selectedBot.is_bot_trained===1 ? true : false)} onClick={updateKnowledgeSource}>Retrain my AI Chatbot</button>

<div id="quickchat-modal-div" scenario_id="qqw0vazxku" conv_id="">
      <div id="quickchat-modal-body">
          <div id="quickchat-header">
            <h5 id="quickchat-first-header">
            </h5>
            <p id="quickchat-second-header"></p>
          </div>
          <div id="quickchat-messages">
            
        <div className="message-container">
        <div className="message-box" id="messageBox" ref={containerRef} >
      {responses?.map((rowData, index) => (
                  <Fragment key={index}>
                    {console.log("response-date")}
                    {console.log(state.response_data[index])}
        <div className={(state.response_data[index])[1] === 'user' ? 'hide-content' : 'second-chat show-content'}>
          <div className="circle" id="circle-mar"></div>
          <p>{(state.response_data[index])[0]}</p>
        </div>

        <div className={(state.response_data[index])[1] === 'bot' ? 'hide-content' : 'first-chat show-content'} >
          <table className="user-message">
            <tbody>
            <tr>
              <td className="user-message-inner" >
                <p>{(state.response_data[index])[0]}</p>
              </td>
              <td className="three-dots-column">
                <div className="three-dots" onClick={event => handleClickOpen(event, (state.response_data[index])[0], (state.response_data[index+1])[2])}></div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        
        </Fragment>
                ))}
                
      </div>
      <div id="typing-indicator" className="typing-indicator">
        <span></span>
        <span></span>
        <span></span>
    </div>
        </div>
      </div>
      </div>
      <div id="quickchat-footer">
        <a href="/#" id="quickchat-livechat-url" target="_blank" rel="noopener"><button id="quickchat-talk-to-human"><svg id="quickchat-icon-human" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-user fa-w-14 fa-3x"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" ></path></svg></button></a>
        <div id="quickchat-input-container">
          <TextareaAutosize id="quickchat-input" placeholder="Write a question" onKeyPress={e => {if(e.key === 'Enter') e.preventDefault()}} maxLength="250" onKeyDown={checkInputTextEnter}></TextareaAutosize>
          <button id="quickchat-send-msg-btn" onClick={userResponse}>
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z" ></path></svg>
          </button>

        </div>
      </div>
    </div></div>

</div>
      
    </Fragment>
  );
}

export default RightSideDrawer;
