import React, { useState } from "react";
// import JessIcon from "../images/avatar4.png";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Logo from "../../assets/images/ezChat_logo.svg";

import "./login.css";
function Login() {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  //   const sendEarlyAccessRequest = async (e) => {
  //     var input_field = document.getElementById("early_access_email_input");
  //     if (input_field.value.length > 0) {
  //       e.preventDefault();
  //       axios
  //         .request({
  //           method: "POST",
  //           url:
  //             process.env.REACT_APP_REST_API_BASE_URL + "/early_access_request",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           data: JSON.stringify({
  //             email: input_field.value,
  //           }),
  //         })
  //         .then((res) => {
  //           setOpen(true);
  //         })
  //         .catch((err) => {});
  //     } else {
  //       alert("Enter valid Email for early access");
  //     }
  //   };

  const loginUser = async (e) => {
    if (credentials.email.length > 0) {
      e.preventDefault();

      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + "/login",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            email: credentials.email,
            password: credentials.password,
          }),
        })
        .then((res) => {
          console.log(res);
          if (res.data.access_token === undefined) {
            alert("Enter valid Email or password");
            // invalid username or password
            return;
          }

          localStorage.setItem("access_token", res.data.access_token);
          window.open("/", "_self");
        })
        .catch((err) => {});
    } else {
      alert("Enter valid Email or password");
    }
  };
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      loginUser();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          titleStyle={{ textAlign: "center" }}
          textAlign="center"
        >
          <b>Thank you!</b>
        </DialogTitle>
        <DialogContent titleStyle={{ textAlign: "center" }} textAlign="center">
          <DialogContentText
            id="alert-dialog-description"
            titleStyle={{ textAlign: "center" }}
            textAlign="center"
          >
            Someone will get in touch shortly
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyItems: "center", justifyContent: "center" }}
        >
          <button
            fullWidth
            type="submit"
            style={{ width: "180px", height: "45px" }}
            className="early_access__dialog_close_button  hover:text-white font-bold py-2 px-4 "
            onClick={handleClose}
          >
            Close
          </button>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </DialogActions>
      </Dialog>
      <section>
        <div className="w-auto h-auto grid  text-white text-4xl md:grid-cols-2 sm:grid-cols-1 overflow-hidden">
          <div className="centered w-full h-full bg-[white] md:h-screen p-10 md:p-36 sm:p-5 md:text-left items-center mt-0 md:mt-10 sm:pt-20">
            <div className="jess_icon left-[35px] md:left-[20px] sm:left-[15px] md:top-[-50px] sm:top-[0px] sm:w-[150px] md:w-[267px] ">
              <img src={Logo} alt="" />
            </div>
            <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
              <div className="teepee-heading  ">
                <p>It talks like a human!</p>
              </div>
              <div className="teepee-sub-heading">
                <p>
                  Smart and simple way to build your own AI-powered assistant
                </p>
              </div>
              <form>
                <div className="mt-[72px]">
                  <input
                    type="text"
                    name="text"
                    style={{ height: "65px" }}
                    className=" teepee-get-early-access-input  border-[#8B8B90] border-[1px] rounded-md w-full py-7 px-4   leading-tight  focus:shadow-blue-900"
                    id="early_access_email_input"
                    placeholder="mail@example.com"
                  />
                </div>
                <div className="mt-[26px] ">
                  <button
                    type="submit"
                    className="teepee-get-early-access-button-text hover:text-white w-[180px] h-[45px]  py-2 px-4  bg-[#101010] rounded-md"
                    // onClick={sendEarlyAccessRequest}
                  >
                    Get early access
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* page 2 */}
          <div className="w-full h-full bg-white centered md:h-screen p-10 md:p-36 sm:p-5">
            <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
              <div className="sign-in-label ">
                <p>Sign In</p>
              </div>
              <div className="sign-in-sub-label ">
                <p>Sign in and build some cool AI assistants</p>
              </div>
              <form>
                <div className="mt-[38px]">
                  <label className="email-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    style={{ height: "65px" }}
                    className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full py-7 px-4 text-black leading-tight  "
                    id="username"
                    placeholder="Enter your Username"
                    value={credentials.email}
                    // onKeyPress={handleKeypress}
                    onChange={onChange}
                  />
                </div>
                <div>
                  <label className="email-label mt-[13px] ">Password</label>
                  <input
                    type="password"
                    name="password"
                    style={{ height: "65px" }}
                    className=" textfield-text border-[#8B8B90] border-[1px] rounded-md w-full py-7 px-4 text-black leading-tight  focus:shadow-blue-900  "
                    id="password"
                    // onKeyPress={handleKeypress}
                    placeholder="Enter your Password"
                    value={credentials.password}
                    onChange={onChange}
                  />
                </div>
                <div className="mt-[26px] ">
                  <button
                    type="submit"
                    className="sign-in-button bg-[#777EFF]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                    onClick={loginUser}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Login;
