import React, { Fragment, useEffect, useContext, useState, useRef } from "react";
import SideDrawer from "./navbar/SideDrawer";
import RightSideDrawer from "./navbar/RightSideDrawer";
import CircularProgress from '@mui/material/CircularProgress';
import appContext from "../context/appContext";
import axios from "axios";
import ContentEditable from 'react-contenteditable'
import { useForm } from "react-hook-form";
export default function KnowledgeBase() {

    const state = useContext(appContext);
    const formOptions = {  };
    const { formState } = useForm(formOptions);
    const { errors } = formState;
    const typingTimer = useRef(0);
    const contentEditable = React.createRef();
    const contentEditable2 = React.createRef();
    const kbHtml = useRef("");
    const wmHtml = useRef("");

    const orignal_bot_name = useRef("");
    const orignal_welcome_messages = useRef("");
    const orignal_short_description = useRef("");
    const orignal_knowledge_base = useRef("");

    const edited_bot_name = useRef("");
    const edited_welcome_messages = useRef("");
    const edited_short_description = useRef("");
    const edited_knowledge_base = useRef("");

    useEffect(() => {
      if(orignal_bot_name.current==="")
      {
        orignal_bot_name.current = state.bnText;
        edited_bot_name.current = state.bnText;
      }
     },[state.bnText]);

     useEffect(() => {
      if(orignal_short_description.current==="")
      {
        orignal_short_description.current = state.sdText;
        edited_short_description.current = state.sdText;
      }
     // edited_short_description.current = state.sdText;
     },[state.sdText]);
    
  // Posting data to server when there is any change in bot name
  const changeBn = (e) => {
    //state.setBnText(e.target.value)
    edited_bot_name.current = e.target.value;//document.getElementById("knowledge_base_input").innerText.split("\n");
    
    sendFormUpdate()
  };

  // Posting data to server when there is any change in short description
  const changeSd = (e) => {
    //state.setSdText(e.target.value);
    edited_short_description.current = e.target.value;//document.getElementById("knowledge_base_input").innerText.split("\n");
    sendFormUpdate();
  };

  const compareArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  // Loading knowledge base in contentEditable
  useEffect(() => {

    let mainDiv = document.getElementById("knowledge_base_input");
    mainDiv.innerHTML=""
    for(let index in state.kbText)
    {
        mainDiv.innerHTML+='<div content_type="Paragraph" data-trigger="" data-toggle="" data-placement="" data-content="" className="black-color">'+state.kbText[index]+'</div>'
    }
    kbHtml.current=mainDiv.innerHTML;
    if(orignal_knowledge_base.current.length===0)
    {
      orignal_knowledge_base.current = state.kbText;
      edited_knowledge_base.current = state.kbText;
    }
   },[state.kbText]);

   // Loading welcome messages in contentEditable
   useEffect(() => {

    let mainDiv = document.getElementById("welcome_message_input");
    mainDiv.innerHTML=""
    for(let index in state.wmText)
    {
        mainDiv.innerHTML+='<div content_type="Paragraph" data-trigger="" data-toggle="" data-placement="" data-content="" className="black-color">'+state.wmText[index]+'</div>'
    }
    wmHtml.current = mainDiv.innerHTML;
    

    //orignal_welcome_messages.current = mainDiv.innerText.split("\n");
    if(orignal_welcome_messages.current.length===0)
    {
      orignal_welcome_messages.current = state.wmText;
      edited_welcome_messages.current = state.wmText;
    }
   
   },[state.wmText]);

   useEffect(() => {
  
      document.getElementById("bot_name_input").value=state.bnText;
    
   },[state.bnText]);

   useEffect(() => {
    
        document.getElementById("short_description_input").value=state.sdText;
      
   },[state.sdText]);

   // Adding bullet in content editable div when it is empty and focused
  const onInputFocus = (e) => {
    if(e.target.childElementCount===0)
    {
      e.target.innerHTML='<div content_type="Paragraph" data-trigger="" data-toggle="" data-placement="" data-content="" className="black-color">'+e.target.innerText+'</div>'
    }
  };

  
  // This function will detect if user has stoped typing for 1 second
  // Then post call a function that post the data to server
  const sendFormUpdate= ()=> {
    
    
    clearTimeout(typingTimer.current);

    typingTimer.current = setTimeout(function() {


      // if no any input field edited then do not send update request
      if(!isAnyInputFieldEdited())
      {
          return;
      }
    

      let  savingPreloader = document.getElementById("saving-preloader");
      let savedPreloader = document.getElementById("saved-preloader");
      savingPreloader.style.display="block"
      savedPreloader.style.display="none"
      
      setTimeout(function() {
        updateTempData()
    }, 1000)

    }, 1000);  
  }

  /**
   * check if there is any change in input fields then return true
   * else return false
   * @returns true/false
   */
  function isAnyInputFieldEdited()
  {
    if(orignal_bot_name.current !== edited_bot_name.current)
    {
      return true;
    }
    if(!compareArrays(orignal_welcome_messages.current, edited_welcome_messages.current))
    {
      return true;
    }
    if(orignal_short_description.current !== edited_short_description.current)
    {
      return true;
    }
    if(!compareArrays(orignal_knowledge_base.current, edited_knowledge_base.current))
    {
        return true;
    }
    return false;
  }

  const onKbChange = evt => {
    kbHtml.current = evt.target.value;
    edited_knowledge_base.current = document.getElementById("knowledge_base_input").innerText.split("\n");
    sendFormUpdate()
  };

  const onWmChange = evt => {
    wmHtml.current = evt.target.value;
    edited_welcome_messages.current =document.getElementById("welcome_message_input").innerText.split("\n");
    sendFormUpdate()
  };

  const updateTempData = () => {

      console.log("calling http request")

      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

      axios.request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL+'/update_temp_data',
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify({
            bot_name: document.getElementById("bot_name_input").value,
            welcome_message: document.getElementById("welcome_message_input").innerText.split("\n"),
            short_description: document.getElementById("short_description_input").value,
            knowledge_base: document.getElementById("knowledge_base_input").innerText.split("\n"),
            bot_id:state.selectedBot.bot_id
          }),
        })
        .then((res) => {
          console.log("erros list");
          console.log(res.data);
          console.log("received response")
          let  savingPreloader = document.getElementById("saving-preloader");
          let savedPreloader = document.getElementById("saved-preloader");

          if(res.data.errors !== undefined && res.data.errors.length>0)
          {
            if(res.data.errors[0].name!=="")
            {
                errors.name=res.data.errors[0].name
                alert("Duplicate name. Please use different name")
                //state.setBnText(state.bot_name)
            }
            savingPreloader.style.display = "none"
            return;
          }
          else{
            errors.name=""
          }
          state.selectedBot.is_bot_trained = 0;
          state.setDisableRetrainButton(0)
          orignal_bot_name.current = document.getElementById("bot_name_input").value;
          orignal_welcome_messages.current = document.getElementById("welcome_message_input").innerText.split("\n");
          orignal_short_description.current = document.getElementById("short_description_input").value;
          orignal_knowledge_base.current = document.getElementById("knowledge_base_input").innerText.split("\n");
          //state.setWmText(document.getElementById("welcome_message_input").innerText.split("\n"));
          //state.setTempWmText(document.getElementById("welcome_message_input").innerText.split("\n"));
          //state.setKbText(document.getElementById("knowledge_base_input").innerText.split("\n"));
          //state.setTempKbText(document.getElementById("knowledge_base_input").innerText.split("\n"));
          
          //let retrain_button = document.getElementById("retrain-ai-button");//.disabled=false;
          //retrain_button.disabled=false;
          //retrain_button.addEventListener("onClick")
          //state.setSelectedBot(state.selectedBot)
          //state.setBnText(document.getElementById("bot_name_input").value);
          
          
          savingPreloader.style.display = "none"
          savedPreloader.style.display = "block"

          setTimeout(function(){
            savedPreloader.style.display="none"
          }, 1000);

          } 
        )
        .catch((err) => {
          console.log("error calling http request")
          console.log(err)
        });
   
  };  

  return (
    <Fragment>
      <SideDrawer />
      
      <div className="pl-[340px]">
        <div className="left-div">
        <div>
            <h2 className="mt-4 fields-title">Product / Service / Project Name</h2>
          </div>
          <div className="chat-window4 short-description bot-name">
          <div className="form-group">
          
          <input
          id="bot_name_input"
          placeholder="Enter Product / Service / Project Name"
            name="name"
            type="text"
            onChange={changeBn}
            //value={state.bnText}
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
           
          />
          <div className="invalid-feedback">{errors.name}</div>
        </div>
            {/* <input
            
              
              className="kn-textarea product-name"
              
              //onKeyUp={sendFormUpdate}
              
              required
            /> */}
          </div>
          <div>
            <h2 className="mt-4 fields-title">Welcome Message</h2>
          </div>
        
          <div className="chat-window4 welcome-message">
          <ContentEditable
              id="welcome_message_input"      
              // innerRef={contentEditable2}
              onFocus={onInputFocus}
              html={wmHtml.current} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={onWmChange} // handle innerHTML change
              tagName='article' // Use a custom HTML tag (uses a div by default)
            />
        </div>
          <div>
            <h2 className="mt-4 fields-title">Short Description</h2>
          </div>
          <div className="chat-window4 short-description">
            <textarea
              id="short_description_input"
              placeholder="Enter short description here..."
              className="kn-textarea short-description"
              onChange={changeSd}
              
              //value={state.sdText}
              
            />
          </div>

          <div>
            <h2 className="mt-4 fields-title">Knowledge Base</h2>
            <div className="">Once you've finished editing, don't forget to Retrain your AI chat bot</div>
            
          </div>

          <div className="chat-window4 knowledge-base">

          <ContentEditable
              id="knowledge_base_input"
              className="knowledge-base"
              // innerRef={contentEditable}
              onFocus={onInputFocus}
              html={kbHtml.current} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={onKbChange} // handle innerHTML change
              tagName='article' // Use a custom HTML tag (uses a div by default)
            />

         </div>
        


        </div>
        <div className="right-div"></div>
      
      </div>
      <div className="saving-preloader-outer-div">
        <p id="saving-preloader" className="saving">
          <CircularProgress className="circular-progress" color="secondary" />
          <span>Saving</span>
        </p>

        <p id="saved-preloader" className="saved save-class2">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="tick-class svg-inline--fa fa-check-circle fa-w-16 fa-3x"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" ></path></svg>
          <span>Saved</span>
        </p>
      </div>
      <RightSideDrawer />
    </Fragment>
  );
  
}
