import React, { Fragment, useContext , useRef, useEffect} from "react";
import SideDrawer from "./navbar/SideDrawer";
import RightSideDrawer from "./navbar/RightSideDrawer";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import {BrowserRouter as Router, Link} from 'react-router-dom';
import axios from "axios";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import appContext from "../context/appContext";
import Button from '@mui/material/Button';

export default function Settings() {
  const state = useContext(appContext);
  const [open, setOpen] = React.useState(false);
  const [openModelUpdateDialog, setOpenModelUpdateDialog] = React.useState(false);
  const [deleteButtonState, setDeleteButtonState] = React.useState(true);
  const selected_model = useRef()
  const [model, setModel] = React.useState();

  useEffect(() => {
    if(state.selectedBot===null)
    {
      return;
    }
    selected_model.current=state.selectedBot.model;
    setModel(selected_model.current);

  },[state.selectedBot]);


  const onChangeText = (e) => {
   let status = (e.target.value===state.selectedBot.bot_name) ? false : true
    setDeleteButtonState(status)
  }

  const handleCloseWithCancel = () => {
    setOpen(false);
  };

  const handleCloseWithYes = () => {

   sendDeleteBotRequest();

    setOpen(false);
  };


  const sendDeleteBotRequest=()=>
  {
    //let tbody = document.getElementById("user_session_tbody");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    console.log(state.selectedBot)

    axios.request({
          method: "GET",
          url: process.env.REACT_APP_REST_API_BASE_URL+'/delete_chat_bot?bot_id='+state.selectedBot.bot_id,
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify({
            
          }),
        }).then(response =>  {
            console.log(response.data)
            window.location.reload(false);
            });
  }

  const onChangeModel=(e)=>
  {
      selected_model.current = e.target.selectedIndex + 1;
      setModel(selected_model.current)
      console.log("bot_model_changed to " + (e.target.selectedIndex + 1))
  }
  const updateBotModel=()=>
  {
    //let tbody = document.getElementById("user_session_tbody");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    console.log(state.selectedBot)

    axios.request({
          method: "GET",
          url: process.env.REACT_APP_REST_API_BASE_URL+'/update_bot_model?bot_id='+state.selectedBot.bot_id+"&model="+selected_model.current,
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify({
           
          }),
        }).then(response =>  {
            console.log(response.data)
            alert("Model Updated successfully")
           // window.location.reload(false);
            });
  }

  const handleDelateOptionClick = (event) => {
    //setUserMessage(param1)
    //setPrompt(param2)
    setOpen(true);
  };

  return (
    <Fragment>
      <SideDrawer />
      <RightSideDrawer />
      
      
      <Dialog
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">

        <DialogTitle id="alert-dialog-title">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This AI Assistant will be deleted and all conversations<br/>with your AI will be lost. <b>This cannot be undone.</b>
          <br/>
          <br/>
          Confirm by typing the name of this AI Assistant:

          <div className="">
            <input
              onChange={onChangeText}
              className="deleting-bot-name-input"
              
            />
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button uppercase={false} onClick={handleCloseWithCancel}>Cancel</Button>
          <Button uppercase={false} disabled={deleteButtonState} className="delete-button" onClick={handleCloseWithYes} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

     

      <div className="pt-[40px] pl-[340px]">
        <div className="left-div">
          <div>
            <h2 className="mt-4 fields-title">Delete</h2>
          </div>
          <div>
          <Link to="/settings" className="delete-link-button" onClick={event => handleDelateOptionClick(event)}>Delete this AI chat bot </Link>
          </div>
        </div>
      
        <div className="home ">
         
       
    
        </div>
        <div>
          <div className=""></div>

          <div className="selectionDiv">
            <div className="form-div">
              <div className="w-[600px]">
              
              <div>
            <h2 className="mt-4 mb-2 fields-title">Select Model</h2>
          </div>
                <div>
                  <div>
                    <select
                      value={model}
                      onChange={onChangeModel}
                      name="quizSubject"
                      className="teepee-select shadow-sm outline-none  leading-[30px]  mb-3 font-[300] px-4 ">
                      <option value="1" id="1">EzBoss</option>
                      <option value="2" id="2">EzCutter</option>
                      <option value="3" id="3">EzNext</option>
                    </select>
                  </div>
                </div>
                <div>
                  <button
                    onClick={updateBotModel}
                    className="train-my-ai-chatbot-button"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
    </Fragment>
  );
}
