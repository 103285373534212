import React, { Fragment, useEffect, useRef, useContext } from "react";
import SideDrawer from "./navbar/SideDrawer";
import RightSideDrawer from "./navbar/RightSideDrawer";
import { BrowserRouter as Router, Link } from "react-router-dom";
import copyIcon from "../assets/icons/ic_copy.svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import appContext from "../context/appContext";
import Button from "@mui/material/Button";
import Prism from "prismjs";
import axios from "axios";
import "../assets/css/prism.css";
import fbMessenger from "../assets/images/facebook-messenger.svg";
import instagram from "../assets/images/instagram-icon.svg";
import { useForm } from "react-hook-form";
import MessengerLogo from "../assets/images/facebook-messenger.svg";
export default function KnowledgeBase() {
  const state = useContext(appContext);
  const [open, setOpen] = React.useState(false);
  const webhookUrl  = useRef("");
  const formOptions = {  };
  const { formState } = useForm(formOptions);
  const { errors } = formState;

  let integrationMethonText = "<body>";
  let text = `\twindow.addEventListener('mouseover', initHSbot, { once: true });\n\twindow.addEventListener('touchstart', initHSbot, { once: true });\n\tfunction initHSbot() {\n\t\tvar s = document.createElement('script');\n\t\ts.type = 'text/javascript';\n\t\ts.async = true;\n\t\ts.setAttribute('data-id', '#web_widget_access_key#');\n\t\ts.src = '#snippet_url#';\n\t\tvar x = document.getElementsByTagName('script')[0];\n\t\tx.parentNode.insertBefore(s, x);\n\t}</script>`;
  const scriptText = useRef(text);
  useEffect(() => {
    if (state.selectedBot != null) {
      let web_widget_access_key = state.selectedBot.web_widget_access_key;
      let message = scriptText.current
        .replace("#web_widget_access_key#", web_widget_access_key)
        .replace("#snippet_url#", process.env.REACT_APP_REST_API_BASE_URL + process.env.REACT_APP_WIDGET_SNIPPET_URL);

      let code_container = document.getElementById("code-container");
      webhookUrl.current = process.env.REACT_APP_REST_API_BASE_URL + process.env.REACT_APP_FACEBOOK_MESSENGER_CALLBACK_URL.replace("#web_widget_access_key#",state.selectedBot.web_widget_access_key)
      code_container.innerHTML = message;
      Prism.highlightAll();
    }
  }, [state.selectedBot]);

  const copyText = async (e) => {
    try {
      var text = document.getElementById("code-container");
      await navigator.clipboard.writeText(
        "<script>\n" + text.innerText + "\n</script>"
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleMessengerOptionClick = (event) => {
    //setUserMessage(param1)
    //setPrompt(param2)
    setOpen(true);
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
  };

  const handleCloseWithYes = () => {
     submitWebhookDetails();

   
  };

  const submitWebhookDetails=()=>
  {
    var verify_token_value = document.getElementById("verify-token").value;
    var page_access_token_value = document.getElementById("page-access-token").value
    if(verify_token_value==="")
    {
      errors.verify_token="verify-token";
      return;
    }
    if(page_access_token_value==="")
    {
      errors.page_access_token="page-access-token";
      return;
    }

    setOpen(false);

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    console.log(state.selectedBot)

    axios.request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL+'/update_webhook_details',
          headers: {
            "Content-Type": "application/json",
            "authorization" : "Bearer "+localStorage.getItem("access_token")
          },
          data: JSON.stringify({
            verify_token: verify_token_value,
            page_access_token: page_access_token_value,
            bot_id:state.selectedBot.bot_id
          }),
        }).then(response =>  {
            console.log(response.data)
            window.location.reload(false);
            });
  }

  const onPageAccessTokenChange = (e) =>{
    //  if(e.target.value==="")
    //  {
    //    errors.page_access_token="page-access-token";
    //  }
    //  else{
    //   errors.page_access_token="";
    //  }
  }

  const onVerifyTokenChange = (e) =>{
    // if(e.target.value==="")
    // {
    //   errors.verify_token="verify-token";
    // }
    // else{
    //  errors.verify_token="";
    // }
 }

  return (
    <Fragment>
      <SideDrawer />
      <RightSideDrawer />

      <Dialog
        PaperProps={{
          sx: {
            height: "658px",
            maxWidth: "800px",
            width: "1000px",
          },
        }}
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ display: "flex" }}>
          <div>
            <img
              style={{
                paddingTop: "20px",
                paddingLeft: "20px",
                height: "60px",
                width: "80px",
              }}
              src={MessengerLogo}
              alt="messenger"
            ></img>
          </div>

          <div>
            <DialogTitle id="alert-dialog-title">
              Facebook Messenger
            </DialogTitle>
          </div>
        </div>
        <div style={{ paddingLeft: "100px" }}>An easier way to message.</div>
        <DialogContent>
          <hr
            style={{ paddingBottom: "20px", borderTop: "2px solid #bbb" }}
          ></hr>
          <DialogContentText id="alert-dialog-description">
            Create and teach a conversational bot for Facebook Messenger. <br />
            <br></br>
            <div className="webhook-description">
            <p>
              After you design and test your Ezchat widget, you can launch your Messenger bot
            </p>
              <ol>
                <li>Get your Facebook Page Access Token and insert it in the field below.</li>
                <li>Create your own Verify Token (can be any string).</li>
                <li>Click 'START' below.</li>
                <li>Use the Callback URL and Verify Token to create an event in the Facebook Messenger Webhook Setup.</li>
              </ol>
            
            </div>
            <div className="input-fields">
              <label className="col-sm-2 webhook-label" htmlFor="callback-url">Callback URL</label>
              <span>
                <input value={webhookUrl.current}  disabled className="col-sm-8 webhook-input-field" name="callback-url"/>
              </span>
              
              
              <span>
                
                <div className="col-md-12 form-group">
                  
                  <label htmlFor="verify-token" className="col-sm-2 webhook-label">Verify Token</label>
                  
                  <input id="verify-token" onChange={onVerifyTokenChange} className={`col-sm-8 webhook-input-field form-control ${errors.verify_token==="verify-token" ? "is-invalid" : ""}`} name="verify-token" type="text" placeholder="Verify Token"/>
                  <div className="ml-[125px] invalid-feedback">{"Please enter verify token"}</div>
                </div>
              
              </span>
            
              
              <span>
                <div className="col-md-12 form-group">
                  <label htmlFor="page-access-token" className="col-sm-2 webhook-label">Page Access Token</label>
                  <input
                  id="page-access-token"
                    name="page-access-token"
                    type="text"
                    placeholder="Page access token"
                    onChange={onPageAccessTokenChange}
                    className={`col-sm-8 webhook-input-field form-control ${errors.page_access_token==="page-access-token" ? "is-invalid" : ""}`}
                  />
                  <div className="ml-[125px] invalid-feedback">{"Please enter page access token"}</div>
                </div>
              
              </span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>
            Close
          </Button>
          <Button
          
            // disabled={deleteButtonState}
            className="delete-button"
            onClick={handleCloseWithYes}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <div className="pl-[340px]">
        <div className="left-div">
          <div>
            <h2 className="mt-4 fields-title">EMBED INTO YOUR WEBSITE</h2>
          </div>
          <div>
            <hr />
          </div>
          <div>
            <h2 className="mt-4 ">
              Copy and paste this code anywhere in the{" "}
              <b>{integrationMethonText}</b> section of your website
            </h2>
            <div className="copy-script-button my-tooltip">
              <Button onClick={copyText} className="copy-script-button">
                <img className="" src={copyIcon} alt="" />
              </Button>
              <span className="my-tooltiptext">Copy</span>
            </div>
          </div>
          <div className="chat-window4 code-script-style">
            <div className="App">
              <div className="Code">
                <pre>
                  <div id="script-starting-tag">{"<script>"}</div>
                  <code
                    id="code-container"
                    className={`language-javascript`}
                  ></code>
                  <div id="script-closing-tag">{"</script>"}</div>
                </pre>
              </div>
            </div>
          </div>

          <div>
            <h2 className="mt-4 fields-title">INTEGRATE WITH SOCIAL MEDIA</h2>
          </div>
          <div>
            <hr />
          </div>
          <div className="container hs-card">
            <div className="row align-items-center mt-4">
              <a onClick={(event) => handleMessengerOptionClick(event)}>
                <div className="card shadow border  hs-card-inner">
                  <div className="card-body d-flex flex-column align-items-center">
                    <img src={fbMessenger} alt="" />
                    <p className="card-text hs-card-text">
                      Messanger from Facebook
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="container hs-card">
            <div className="row align-items-center mt-4">
              <a href="#!">
                <div className="card shadow border hs-card-inner">
                  <div className="card-body d-flex flex-column align-items-center">
                    <img src={instagram} alt="" />
                    <p className="card-text hs-card-text">Instagram</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
