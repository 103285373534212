import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

import { Fragment } from "react";

import Login from "./Authentication/login/Login";
import KnowledgeBase from "./components/KnowledgeBase"
import AssignTopic from "./components/AssignTopic";
import Settings from "./components/Settings";
import Integration from "./components/Integration";
import Inbox from "./components/Inbox";
import Chat from "./components/widget/Chat";
import Preview from "./components/Preview";

function App() {

  function isLoggedIn() {
    
    let token = localStorage.getItem("access_token");

      if(!token)
      {
        return false;
      }

      return true;

      // let res = await axios
      //   .request({
      //     method: "GET",
      //     url: process.env.REACT_APP_REST_API_BASE_URL+"/is_logged_in",
      //     headers: {
      //       "Content-Type": "application/json",
      //       "authorization" : "Bearer "+localStorage.getItem("access_token")
      //     },
      //     data: JSON.stringify({
           
      //     }),
      //   });
      
      //     console.log(res);
      //     if(res.data)
      //     {
      //       // invalid token
      //       return false;
      //     }
      //     return true;
    }

  return (
    <Fragment>
      <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/chat/" element={<Chat />}></Route>
          <Route path="/preview/" element={<Preview />}></Route>
          <Route exact path="/knowledge_base" element={isLoggedIn() ? <KnowledgeBase /> : <Navigate replace to={"/login"} />}/>
          <Route exact path="/assign_topic" element={isLoggedIn() ? <AssignTopic /> : <Navigate replace to={"/login"} />}/>
          <Route exact path="/settings" element={isLoggedIn() ? <Settings /> : <Navigate replace to={"/login"} />}/>
          <Route exact path="/integration" element={isLoggedIn() ? <Integration /> : <Navigate replace to={"/login"} />}/>
          <Route exact path="/" element={isLoggedIn() ? <Inbox /> : <Navigate replace to={"/login"} />}/>
          <Route exact path="/knowledge_base" element={isLoggedIn() ? <KnowledgeBase /> : <Navigate replace to={"/login"} />}/>     

      </Routes>
    </Fragment>
  );
}

export default App;
