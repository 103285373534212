import React, { Fragment, useEffect } from "react";
export default function Chat() {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  var bot_id = params.get("bot_id");

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute('data-id', bot_id);
    script.async = true;
    script.src = process.env.REACT_APP_REST_API_BASE_URL + process.env.REACT_APP_WIDGET_SNIPPET_URL;
    document.body.appendChild(script);
},[bot_id]);
  
  return (
    <Fragment>
      
    </Fragment>
  );
}
