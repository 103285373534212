import React, { useContext } from "react";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import appContext from "../context/appContext";
import myApi from "../myApi";
import "../assets/css/style.css"
export default function ErrorMsg() {
  const state = useContext(appContext);

  const yupValidation = Yup.object().shape({
    name: Yup.string()
      .required("Please enter name")
      .min(4, "Add minimum 4 characters")
      .test("is-tea", "Name is not available", value => isBotNameAvailable(value)),

    email: Yup.string().required("Email id is mendatory").email()
  });
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  function onSubmit(data) {
    console.log(JSON.stringify(data, null, 4));
    return false;
  }


  const isBotNameAvailable = (value) => {
    console.log(value);
    return  myApi.get('/is_bot_name_available?bot_name='+value).then(result => {
      let res =  result.data;  
      console.log(res);
      if(res["available"])
      {
        return true
      }
      return false;
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <div className="pl-[340px] pr-[380px] pt-[80px] ">
      <h2>React Integrate Validation Error Messages with Hook Form</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Name</label>
          <input
            name="name"
            type="text"
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
            {...register("name")}
          />
          <div className="invalid-feedback">{errors.name?.message}</div>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            name="email"
            type="text"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            {...register("email")}
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
        </div>
        <div className="mt-3">
          <button type="submit" className="train-my-ai-chatbot-button">
            Send
          </button>
        </div>
      </form>
    </div>
  );

 

}


