import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import appContext from "../../context/appContext";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import chatIcon from "../../assets/images/chat_icon.png";
import { io } from "socket.io-client";
import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import WebSocketCall from "../WebSocketCall";
export default function Chat() {
  const state = useContext(appContext);
  const [socketSessionID, setSocketSessionID] = useState("");
  const [socketInstance, setSocketInstance] = useState("")
  const [connected, setConnected] = useState(false)
  const [loading, setLoading] = useState(true);
  //const [userSessionName, setUserSessionName] = useState("");
  const responses = useRef([]);
  const timer = useRef([]);
  const timeoutRef= useRef(null);
  const containerRef = useRef(null);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  
  var bot_id = params.get("bot_id");

  // useEffect(() => {
  //   if(connected && socketInstance!=null){
  //   socketInstance.on("data", (data) => {
  //     //let prompt = response.data['prompt'];
  //      //state.setUserSession(response.data['session_name']);
  //      console.log("manual_response_received");
  //      console.log(data);


  //     let arr = [data['data'],'bot',"Manual response sent by the admin"]

  //     responses.current.push(arr);
  
  //     state.setResponseData(responses.current);

  //     let audio3 = new Audio(
  //       "https://prodigits.co.uk/content/ringtones/tone/2020/alert/preview/4331e9c25345461.mp3"
  //     );
  //     audio3.load();
  //     audio3.play();


  //     var objDiv = document.getElementById("messageBox");
  //     objDiv.scrollTop = objDiv.scrollHeight;
  //    // setMessages([...messages, data.data]);
  //   });
  //   return () => {
  //     socketInstance.current.off("data", () => {
  //       console.log("data event was removed");
  //     });
  //   };
  // }
  // }, [connected]);

  function connectChatSession(buttonStatus)
  {
    
  if (buttonStatus === true) {
    const socket = io(process.env.REACT_APP_WEB_SOCKET_URL, {
      transports: ["websocket"],
      cors: {
        //origin: "http://localhost:3000/",
      },
    });

    setSocketInstance(socket);

    socket.on("connect", (data) => {
     // console.log(data);
     setSocketSessionID(socket.id)
      socket.emit("update_session_id", {socket_session_id: socket.id, user_session_id: state.user_session_id});
      console.log("socket connected");
      setConnected(true);
    });

    setLoading(false);
    

    socket.on("disconnect", (data) => {
      //console.log(data);
      setSocketSessionID("")
      //socket.emit("update_session_id", {session_id: ""});
      console.log("socket disconnected");
    });

    socket.on("data", (data) => {
      //let prompt = response.data['prompt'];
       //state.setUserSession(response.data['session_name']);
       console.log("manual_response_received");
       console.log(data);
       
       showBotResponse(data['data']['answer'])

      // let arr = [data['data'],'bot',"Manual response sent by the admin"]

      // responses.current.push(arr);
  
      //state.setResponseData(responses.current);

      // let audio3 = new Audio(
      //   "https://prodigits.co.uk/content/ringtones/tone/2020/alert/preview/4331e9c25345461.mp3"
      // );
      // audio3.load();
      // audio3.play();


      var objDiv = document.getElementById("messageBox");
      objDiv.scrollTop = objDiv.scrollHeight;
     // setMessages([...messages, data.data]);
    });

  }
  else{
    
   
  //   socketInstance.disconnect();
      //setSessionID("")   
  }
}



  //var typingIndicator;
  const chatOpen = () => {
    let audio1 = new Audio(
      "https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/clickUp.mp3"
    );
    //let typingIndicator = document.getElementById("typing-indicator");
    document.getElementById("chat-open").style.display = "none";
    document.getElementById("chat-close").style.display = "block";
    document.getElementById("chat-window1").style.display = "block";

    // hide typing indicator
    // hideTypingIndicator();
    openConversation();
    audio1.load();
    audio1.play();
  };

  function chatClose() {
    connectChatSession(false)
    document.getElementById("chat-open").style.display = "block";
    document.getElementById("chat-close").style.display = "none";
    document.getElementById("chat-window1").style.display = "none";
    document.getElementById("chat-window2").style.display = "none";
    //document.getElementById("messageBox").innerHTML = "";
    //document.getElementById("quickchat-send-msg-btn").removeEventListener("click", onSendButtonCLick);
    //document.getElementById("quickchat-input").removeEventListener("keyup", onInputKeyDown);
    let audio1 = new Audio(
      "https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/clickUp.mp3"
    );
    audio1.load();
    audio1.play();
  }

  function openConversation() {
    connectChatSession(true);
    let audio1 = new Audio(
      "https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/clickUp.mp3"
    );
    document.getElementById("chat-window2").style.display = "block";
    document.getElementById("chat-window1").style.display = "none";
    get_welcome_messages();

    //  document.getElementById("quickchat-input").addEventListener("keydown", event => {
    //        if (event.keyCode === 13) {
    //            event.preventDefault();
    //        }
    //    });

    //   document.getElementById("quickchat-send-msg-btn").addEventListener("click", onSendButtonCLick);
    //   document.getElementById("quickchat-input").addEventListener("keyup", onInputKeyDown);

    audio1.load();
    audio1.play();
  }

  //   function onSendButtonCLick(event){
  //     let input = document.getElementById("quickchat-input")
  //     //autosize(input)
  //     userResponse(input.value)
  // }

  const checkInputTextEnter = (e) => {
    // autosize(document.getElementById("quickchat-input"))
    if (e.key === "Enter") {
      userResponse();
    }
  };

  // function onInputKeyDown(event){
  //     autosize(document.getElementById("quickchat-input"))
  //     if (event.keyCode === 13) {

  //         userResponse(event.currentTarget.value)

  //     } else {
  //       //  resetRecordingButtons()
  //     }
  // }

  function get_welcome_messages() {
    responses.current=[];
    stopWelcomeMessages();
    let dt = { 'bot_id':  params.get("bot_id")};
  
    showTypingIndicator();
  
    console.log(JSON.stringify(dt))
  
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  
        
    axios.post(process.env.REACT_APP_REST_API_BASE_URL+'/get_welcome_messages', { method: 'POST', body: JSON.stringify(dt) })
    
          .then(response => {
            console.log(response)
            //let prompt = response.data['prompt'];
            var messageBox = document.getElementById("messageBox");
            hideTypingIndicator();
  
            let user_messages = response.data['user_messages'];
  
            //clearTimeout(timeoutRef.current);
  
            for (var i = 0; i < user_messages.length; i++) { 
                    (function(i,timer) {
                      var timerRef = setTimeout(function () {
                      //  messageBox.innerHTML += "<div class='second-chat'><div class='circle' id='circle-mar'></div><p class='admin-bot-message'>" + user_messages[i] + "</p></div>";
                        showBotResponse(user_messages[i]);
                      }, 1000 * i);
                      timer.current.push(timerRef);
                    })(i,timer);
                }
              messageBox.scrollTop = messageBox.scrollHeight;
    })
    .catch((error) => {
      console.log(error);
    });
  }

function stopWelcomeMessages()
{
    for(var i = 0; i < timer.current.length;i++){
      clearTimeout(timer.current[i]);
    }
    timer.current = [];
}

  useEffect(() => {
   
    if(containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth"
      })
    }
    
   },[containerRef, state.response_data]);

   function showBotResponse(message)
   {
  
             let arr = [message,'bot','']
             responses.current.push(arr)  
             const responses1 = responses.current.map( x => ({...x}) );
       
             state.setResponseData(responses1);
   }

   function showTypingIndicator()
   {
     let typingIndicator = document.getElementById("typing-indicator");
     if (typeof typingIndicator !== 'undefined'){
     typingIndicator.style.display = "block";
     }
   }
   
   function hideTypingIndicator()
   {
     let typingIndicator = document.getElementById("typing-indicator");
     if (typeof typingIndicator !== 'undefined'){
      typingIndicator.style.display = "none";
     }
       
   }

   function isEmptyOrSpaces(str){
    return str === null || str.match(/^ *$/) !== null;
  }

  const userResponse = () => {
    console.log("response");
    let userText = document.getElementById("quickchat-input").value;
    console.log(userText);
    if(isEmptyOrSpaces(userText)){
   
      alert("Please type something!");
      }
    else {
    
    
    let arr = [userText,'user','']
      responses.current.push(arr)
      
      console.log("seting respones list in response_data")
      console.log(responses.current);
      
      const responses1 = responses.current.map( x => ({...x}) );
      
      state.setResponseData(responses1);

    let audio3 = new Audio(
      "https://prodigits.co.uk/content/ringtones/tone/2020/alert/preview/4331e9c25345461.mp3"
    );
    audio3.load();
    audio3.play();
    
    let dt = { 'msg': userText ,"forwardMessageFlag": true, "bot_id": bot_id, "socket_session_id": socketSessionID ,"session_name": state.user_session}
    document.getElementById("quickchat-input").value = "";
    var objDiv = document.getElementById("messageBox");
    objDiv.scrollTop = objDiv.scrollHeight;

    setTimeout(() => {
     adminResponse(dt);
    }, 1000);
  }
  };

  //admin Respononse to user's message
  function adminResponse(dt) {
    showTypingIndicator();

  console.log(JSON.stringify(dt))

  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

      
  axios.post(process.env.REACT_APP_REST_API_BASE_URL+'/get', { method: 'POST', body: JSON.stringify(dt) })
  
        .then(response => {

        console.log(response)
        //let prompt = response.data['prompt'];
       
         state.setUserSession(response.data['session_name']);
        
         console.log("user_session_is: "+state.user_session);
       
        hideTypingIndicator();

  let arr = [response.data['response'],'bot',response.data['prompt']]
  
  if(response.data['response']!==''){
    responses.current.push(arr);
  }
  
    
  state.setResponseData(responses.current);

  let audio3 = new Audio(
    "https://prodigits.co.uk/content/ringtones/tone/2020/alert/preview/4331e9c25345461.mp3"
  );
  audio3.load();
  audio3.play();
  

  var objDiv = document.getElementById("messageBox");
  objDiv.scrollTop = objDiv.scrollHeight;
        
            })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Fragment>
      {/*<output>{"sessionID: "+socketSessionID}</output>
           <div className="line">
           // {!loading && <WebSocketCall socket={socketInstance} />}
          </div> */}
      <div className="chat-bar-open" id="chat-open">
        <button
          id="chat-open-button"
          type="button"
          className="collapsible close"
          onClick={chatOpen}
        >
          <img
            alt=""
            src={chatIcon}
          />
        </button>
      </div>

      <div className="chat-bar-close" id="chat-close">
        
        <button
          id="chat-close-button"
          type="button"
          className="collapsible close"
          onClick={chatClose}
        >
          <i className="icons8-close"> </i>
        </button>
      </div>

      <div className="chat-window" id="chat-window1">
        
      </div>

      <div className="chat-window2" id="chat-window2">
      <div className="message-container">
        <div className="message-box chat-widget" id="messageBox" ref={containerRef}>

        {responses.current?.map((rowData, index) => (
                  <Fragment key={index}>
                    {console.log("response-date")}
                    {console.log(state.response_data[index])}
        <div className={(state.response_data[index])[1] === 'user' ? 'hide-content' : 'second-chat show-content'}>
          <div className="circle" id="circle-mar"></div>
          <p>{(state.response_data[index])[0]}</p>
        </div>

        <div className={(state.response_data[index])[1] === 'bot' ? 'hide-content' : 'first-chat show-content'} >
          <table className="user-message">
            <tbody>
            <tr>
              <td className="user-message-inner" >
                <p>{(state.response_data[index])[0]}</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        
        </Fragment>
                ))}
            
        </div>
        </div>



        <div id="typing-indicator" className="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div id="quickchat-footer">
          <div id="quickchat-input-container">
            <TextareaAutosize
              id="quickchat-input"
              placeholder="Write a question"
              onKeyPress={e => {
                if(e.key === 'Enter')
                   e.preventDefault()
                }}
              maxLength="250"
              onKeyDown={checkInputTextEnter}
            ></TextareaAutosize>
            <button id="quickchat-send-msg-btn" onClick={userResponse}>
              <svg
                aria-hidden="true"
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
