import React, { Fragment, useContext } from "react";
import SideDrawer from "./navbar/SideDrawer";
import RightSideDrawer from "./navbar/RightSideDrawer";
import ErrorMsg from "./ErrorMsg";
import appContext from "../context/appContext";

export default function KnowledgeBase() {
  // const state = useContext(appContext);

  // const changeWm = (e) => {
  //   state.setWmText(e.target.value);
  // };
  // const changeSd = (e) => {
  //   state.setSdText(e.target.value);
  // };
  // const changeKb = (e) => {
  //   state.setKbText(e.target.value);
  // };
  


  return (
    <Fragment>
      <SideDrawer />
      <RightSideDrawer />
      <ErrorMsg />
      
      
    </Fragment>
  );
}
